<template>
  <div>
    <!-- ultimos pedidos -->
    <Header 
      class="pb-6"
      button="Comprar agora"
      title="Meus Pedidos"
      subtitle="Confira seus últimos pedidos efetuados."
      @click="$router.push('/produtos')"
    >
      <div class="mt-5 overflow-hidden">
        <ul v-if="orders.length" class="divide-y divide-gray-200">
          <li v-for="(order, index) in orders" :key="index">
            <a @click="orderFocus = order, $modal.show('orderFull')" class="block hover:bg-gray-100 px-2 cursor-pointer">
              <div class="py-4">
                <div class="flex items-center justify-between">
                  <p class="text-sm font-medium text-primarylight truncate">
                    Pedido #{{ order.id.substring(0, 6).toUpperCase() }}
                  </p>
                  <div class="ml-2 flex-shrink-0 flex">
                    <p class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                      Finalizado
                    </p>
                  </div>
                </div>
                <div class="mt-2 hidden md:flex sm:flex sm:justify-between">
                  <p class="flex items-center font-medium text-sm text-gray-500">
                    {{ order.data().items.reduce((ac, cr) => ac + (cr.amount * cr.price), 0) | currency }}
                  </p>
                  <div class="mt-2 flex items-center text-xs text-gray-500 font-medium sm:mt-0">
                    {{ order.data().createdAt | elapsedTime }}
                  </div>
                </div>
              </div>
            </a>
          </li>
        </ul>
        <div v-else>
          <div class="inline-flex items-center text-gray-500">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-1.5 hidden sm:block" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
            </svg>
            Ops, parece que você ainda não realizou nenhum pedido..
          </div>
        </div>
      </div>
    </Header>
    <!-- meus endereços -->
    <Header 
      class="pb-6"
      button="Novo Endereço"
      title="Meus Endereços"
      subtitle="Gerencie seus endereços de entrega."
      @click="$modal.show('newAddress')"
    >
      <div class="mt-5 overflow-hidden">
        <ul v-if="addresses.length" class="divide-y divide-gray-200">
          <li v-for="(address, index) in addresses" :key="index">
            <a class="block">
              <div class="py-2">
                <div class="flex items-center justify-between">
                  <p class="text-sm font-medium text-primarylight truncate">
                    {{ address.data().Name }}
                  </p>
                  <div @click="$modal.show('deleteAddress'), addressFocus = address" class="flex-shrink-0 flex hover:bg-gray-200 rounded-full p-1 text-gray-500 hover:text-primary">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                    </svg>
                  </div>
                </div>
                <div class="mt-2 hidden md:flex sm:flex sm:justify-between">
                  <div>
                    <p class="flex items-center text-sm text-gray-500">
                      <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
                      </svg>
                      {{ address.data().Street + ', ' + address.data().Number + '. ' + address.data().Neighborhood + ' - ' + address.data().City + ' / ' + address.data().State }}
                    </p>
                  </div>
                </div>
              </div>
            </a>
          </li>
        </ul>
        <div v-else>
          <div class="inline-flex items-center text-gray-500">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-1.5 hidden sm:block" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
            </svg>
            Ops, parece que você ainda não cadastrou nenhum endereço para entrega..
          </div>
        </div>
      </div>
    </Header>
    <!-- meus dados -->
    <Header
      class="pb-6"
      title="Meus Dados"
      :button="$store.state.user.Method ? '' : 'Alterar Senha'"
      subtitle="Confira seus dados pessoais."
      @click="$store.state.user.Method ? '' : $modal.show('newPassword')"
    >
      <div v-if="myData.docm.length" class="mt-5 overflow-hidden p-1">
        <div class="grid grid-cols-6 gap-6">
          <div class="col-span-6 sm:col-span-3">
            <label for="name" class="block text-sm font-medium text-gray-700">{{ myData.docm.length > 14 ? 'Razão Social' : 'Nome Completo' }}</label>
            <input v-model="myData.name" type="text" name="name" id="name" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
          </div>
          <div :class="myData.docm.length > 14 ? 'col-span-6 sm:col-span-3' : 'col-span-6 sm:col-span-2'">
            <label for="phone" class="block text-sm font-medium text-gray-700">Telefone</label>
            <input v-model="myData.cell" v-mask="['(##) ####-####', '(##) #####-####']" inputmode="numeric" autocomplete="off" type="text" name="phone" id="phone" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
          </div>
          <div v-if="myData.docm.length <= 14" class="col-span-6 sm:col-span-1">
            <label for="nasc" class="block text-sm font-medium text-gray-700">Nascimento</label>
            <input v-model="myData.nasc" v-mask="'##/##/####'" inputmode="numeric" autocomplete="off" type="text" name="nasc" id="nasc" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
          </div>
          <div class="col-span-6 sm:col-span-3">
            <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
            <input :value="myData.email" disabled type="text" name="email" id="email" class="mt-1 bg-gray-50 text-gray-400 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
          </div>
          <div class="col-span-6 sm:col-span-3">
            <label for="document" class="block text-sm font-medium text-gray-700">{{ myData.docm.length > 14 ? 'CNPJ' : 'CPF' }}</label>
            <input :value="myData.docm" disabled type="text" autocomplete="off" name="document" id="document" class="mt-1 bg-gray-50 text-gray-400 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
          </div>
        </div>
        <div v-if="myData.docm.length > 14">
          <div class="mt-6 grid grid-cols-6 gap-6">
            <div class="col-span-6 sm:col-span-3">
              <label for="nameFantasia" class="block text-sm font-medium text-gray-700">Nome Fantasia</label>
              <input v-model="myData.juri.nfan" type="text" name="nameFantasia" id="nameFantasia" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
            </div>
            <div class="col-span-6 sm:col-span-3">
              <label for="ie" class="block text-sm font-medium text-gray-700">Inscrição Estadual</label>
              <input v-model="myData.juri.insc" type="text" name="ie" id="ie" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
            </div>
          </div>
          <label class="mt-4 mb-3 block text-sm font-medium text-primary">Endereço Comercial</label><hr class="mb-4" />
          <div class="grid grid-cols-6 gap-6">
            <div class="col-span-6 sm:col-span-1">
              <label for="cepJur" class="block text-sm font-medium text-gray-700">Cep</label>
              <input @change="getAddress(myData.juri.end2.cep, 'JURI')" v-model="myData.juri.end2.cep" v-mask="'#####-###'" type="text" name="cepJur" id="cepJur" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
            </div>
            <div class="col-span-6 sm:col-span-2">
              <label for="streetJur" class="block text-sm font-medium text-gray-700">Endereço</label>
              <input v-model="myData.juri.end2.street" type="text" name="streetJur" id="streetJur" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
            </div>
            <div class="col-span-6 sm:col-span-1">
              <label for="numberJur" class="block text-sm font-medium text-gray-700">Número</label>
              <input v-model="myData.juri.end2.number" type="text" name="numberJur" id="numberJur" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
            </div>
            <div class="col-span-6 sm:col-span-2">
              <label for="complementJur" class="block text-sm font-medium text-gray-700">Complemento</label>
              <input v-model="myData.juri.end2.complement" type="text" name="complementJur" id="complementJur" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
            </div>
            <div class="col-span-6 sm:col-span-3">
              <label for="neighborhoodJur" class="block text-sm font-medium text-gray-700">Bairro</label>
              <input v-model="myData.juri.end2.neighborhood" type="text" name="neighborhoodJur" id="neighborhoodJur" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
            </div>
            <div class="col-span-6 sm:col-span-2">
              <label for="cityJur" class="block text-sm font-medium text-gray-700">Cidade</label>
              <input v-model="myData.juri.end2.city" type="text" name="cityJur" id="cityJur" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
            </div>
            <div class="col-span-6 sm:col-span-1">
              <label for="stateJur" class="block text-sm font-medium text-gray-700">Estado</label>
              <input v-model="myData.juri.end2.state" type="text" name="stateJur" id="stateJur" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
            </div>
          </div>
        </div>
        <button @click="updateData()" class="mt-6 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">Salvar</button>
      </div>
      <button v-else @click="$modal.show('complete')" class="mt-6 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">Completar Cadastro</button>
    </Header>
    <t-modal name="newAddress" class="z-45">
      <p class="text-base font-medium text-gray-800">Novo Endereço</p>
      <form @submit.prevent class="mt-4">
        <div class="grid grid-cols-2 gap-2">
          <div class="col-span-1">
            <label for="name" class="block text-sm font-medium text-gray-700">Apelido</label>
            <input v-model="newAddress.name" type="text" name="name" id="name" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
          </div>
          <div class="col-span-1">
            <label for="cep" class="block text-sm font-medium text-gray-700">Cep</label>
            <input v-model="newAddress.cep" @input="getAddress(newAddress.cep)" v-mask="'#####-###'" type="text" name="cep" id="cep" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
          </div>
          <div class="col-span-2">
            <label for="street" class="block text-sm font-medium text-gray-700">Endereço</label>
            <input v-model="newAddress.street" type="text" name="street" id="street" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
          </div>
          <div class="col-span-1">
            <label for="number" class="block text-sm font-medium text-gray-700">Número</label>
            <input v-model="newAddress.number" type="text" name="number" id="number" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
          </div>
          <div class="col-span-1">
            <label for="neighborhood" class="block text-sm font-medium text-gray-700">Bairro</label>
            <input v-model="newAddress.neighborhood" type="text" name="neighborhood" id="neighborhood" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
          </div>
          <div class="col-span-2">
            <label for="complement" class="block text-sm font-medium text-gray-700">Complemento</label>
            <input v-model="newAddress.complement" type="text" name="complement" id="complement" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
          </div>
          <div class="col-span-1">
            <label for="city" class="block text-sm font-medium text-gray-700">Cidade</label>
            <input v-model="newAddress.city" type="text" name="city" id="city" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
          </div>
          <div class="col-span-1">
            <label for="state" class="block text-sm font-medium text-gray-700">Estado</label>
            <input v-model="newAddress.state" type="text" name="state" id="state" class="mt-1 bg-gray-50 text-gray-900 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
          </div>
        </div>
        <button @click="saveAddress()" type="submit" class="mt-4 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
          Salvar
        </button>
      </form>
    </t-modal>
    <t-modal name="deleteAddress" class="z-45">
      <p class="text-base font-medium text-gray-800">Apagar Endereço</p>
      <form @submit.prevent class="mt-8 text-center">
        <p class="font-medium">Tem certeza que deseja excluir esse endereço?</p>
        <p class="text-gray-600 text-sm mb-4">Ele não poderá mais ser utilizado em futuras compras.</p>
        <button @click="deleteAddress()" type="submit" class="mt-4 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
          Apagar
        </button>
      </form>
    </t-modal> 
    <t-modal name="orderFull" class="z-45">
      <div v-if="orderFocus.id" class="px-2 overflow-auto">
        <p class="mt-4 text-xl font-semibold text-primarylight">Pedido #{{ orderFocus.id.substring(0, 6).toUpperCase() }}</p>
        <p class="mt-2 flex items-center text-center text-sm font-medium text-gray-500">
          <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
          </svg>
          <span class="text-primary">{{ new Date(orderFocus.data().createdAt).toLocaleString() }}</span>
          <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 ml-2 mr-1.5 h-5 w-5 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          Total: 
          <span class="ml-1 text-primary">{{ orderFocus.data().items.reduce((ac, cr) => ac + (cr.amount * cr.price), 0) + orderFocus.data().frete.value | currency }}</span>
        </p>
        <p class="mt-4 text-lg font-medium text-primarylight border-b border-gray-200">Itens:</p>
        <ul class="divide-y divide-gray-200">
          <li v-for="(item, index) in orderFocus.data().items" :key="index" class="py-4 flex">
            <img class="h-10 w-10 rounded-lg" :src="item.image" :alt="item.title">
            <div class="ml-3">
              <p class="text-sm font-medium text-gray-900">
                <span class="mr-1 mb-1 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-primary text-white">
                  {{ item.amount }}x
                </span>
                {{ item.title }} 
              </p>
              <p class="text-sm font-medium ml-0.5 text-gray-600">{{ item.price * item.amount | currency }}</p>
            </div>
          </li>
        </ul>
        <p class="mt-4 text-lg flex justify-between font-medium text-primarylight border-b border-gray-200">
          Frete:
          <span class="text-base text-gray-600">{{ orderFocus.data().frete.value || 0 | currency}}</span>
        </p>
      </div>
    </t-modal>
    <t-modal name="newPassword" class="z-45">
      <p class="text-base font-medium text-gray-800">Alterar Senha</p>
      <form @submit.prevent class="mt-4">
        <label for="oldPassword" class="mt-2 block text-sm font-medium text-gray-700">Senha Antiga</label>
        <div class="relative mt-1">
          <input v-model="newPassword.old" :type="newPassword.showO ? 'text' : 'password'" name="oldPassword" id="oldPassword" autocomplete="off" class="bg-gray-50 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
          <div class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 text-gray-300 hover:text-primary">
            <svg v-if="!newPassword.showO" @click="newPassword.showO = true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z" /><path fill-rule="evenodd" clip-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" /></svg>
            <svg v-else @click="newPassword.showO = false" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z" /><path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" /></svg>
          </div>
        </div>
        <label for="newPassword" class="mt-2 block text-sm font-medium text-gray-700">Nova Senha</label>
        <div class="relative mt-1">
          <input v-model="newPassword.new" :type="newPassword.showN ? 'text' : 'password'" name="newPassword" id="newPassword" autocomplete="off" class="bg-gray-50 shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md">
          <div class="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 text-gray-300 hover:text-primary">
            <svg v-if="!newPassword.showN" @click="newPassword.showN = true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 12a2 2 0 100-4 2 2 0 000 4z" /><path fill-rule="evenodd" clip-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" /></svg>
            <svg v-else @click="newPassword.showN = false" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z" /><path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" /></svg>
          </div>
        </div>
        <button @click="updatePassword()" type="submit" class="mt-4 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">Atualizar</button>
      </form>
    </t-modal> 
  </div>
</template>

<script>
import axios from 'axios'
import { mask } from 'vue-the-mask'
import { firebase, auth, ordersColl, usersColl } from '@/firebase'

export default {
  components: { Header: () => import('@/components/layout/header') },

  data() {
    return {
      myData: {
        name: '',
        cell: '',
        nasc: '',
        docm: '',
        email: '',
        juri: {
          insc: '',
          nfan: '',
          end2: {
            cep: '',
            street: '',
            number: '',
            city: '',
            state: '',
            complement: '',
            neighborhood: '',
          }
        },
      },
      newPassword: {
        old: '',
        new: '',
        showO: false,
        showN: false,
      },
      newAddress: {
        cep: '',
        name: '',
        street: '',
        number: '',
        city: '',
        state: '',
        complement: '',
        neighborhood: '',
      },
      orders: [],
      addresses: [],
      addressFocus: '',
      orderFocus: {},
    }
  },

  mounted() { this.getOrders(), this.getAddresses(), this.getInfo() },

  methods: {
    getInfo() {
      this.myData.name = this.$store.state.user.Name || ''
      this.myData.cell = this.$store.state.user.Phone || ''
      this.myData.nasc = this.$store.state.user.Nascimento || ''
      this.myData.email = this.$store.state.user.Email || ''
      this.myData.docm = this.$store.state.user.Document || ''
      this.myData.juri.insc = this.$store.state.user?.Juridico?.Ie || ''
      this.myData.juri.nfan = this.$store.state.user?.Juridico?.NameFantasia || ''
      this.myData.juri.end2.cep = this.$store.state.user?.Juridico?.Address?.Postcode || ''
      this.myData.juri.end2.city = this.$store.state.user?.Juridico?.Address?.City || ''
      this.myData.juri.end2.state = this.$store.state.user?.Juridico?.Address?.State || ''
      this.myData.juri.end2.street = this.$store.state.user?.Juridico?.Address?.Street || ''
      this.myData.juri.end2.number = this.$store.state.user?.Juridico?.Address?.Number || ''
      this.myData.juri.end2.complement = this.$store.state.user?.Juridico?.Address?.Complement || ''
      this.myData.juri.end2.neighborhood = this.$store.state.user?.Juridico?.Address?.Neighborhood || ''
    },

    async getAddresses() {
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      await usersColl.doc(auth.currentUser.uid).collection('Addresses').get()
      .then(result => this.addresses = result.docs)
      .catch(() => this.$toast.error('Erro ao obter seus endereços, tente novamente'))
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    },

    async getOrders() {
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      await ordersColl.where('id', '==', auth.currentUser.uid).orderBy('createdAt', 'desc').limit(5).get()
      .then(result => this.orders = result.docs)
      .catch(() => this.$toast.error('Erro ao obter seus pedidos, tente novamente'))
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    },

    async getAddress(cep, from = '') {
      if (cep.length === 9) {
        if (!this.$store.state.loading) this.$store.commit('setLoading', true)
        await axios.get('https://brasilapi.com.br/api/cep/v1/' + cep.replace('-', ''))
        .then(result => { 
          if (result.status === 200) {
            if (from === 'JURI') {
              this.myData.juri.end2.city = result.data.city
              this.myData.juri.end2.state = result.data.state
              this.myData.juri.end2.street = result.data.street
              this.myData.juri.end2.neighborhood = result.data.neighborhood
            } else {
              this.newAddress.city = result.data.city
              this.newAddress.state = result.data.state
              this.newAddress.street = result.data.street
              this.newAddress.neighborhood = result.data.neighborhood
            }
          }
        }).catch(() => {})
        if (this.$store.state.loading) this.$store.commit('setLoading', false)
      }
    },

    async saveAddress() {
      if(this.addresses.length >= 3) { this.$toast.error("Você atingiu a quantidade máxima de endereços cadastrados"); return}

      if (!this.newAddress.name) { this.$toast.error("Preencha um apelido para o endereço"); return }
      if (!this.newAddress.cep) { this.$toast.error("Preencha o cep do endereço"); return }
      if (!this.newAddress.street) { this.$toast.error("Preencha o endereço corretamente"); return }
      if (!this.newAddress.neighborhood) { this.$toast.error("Preencha o bairro do endereço"); return }
      if (!this.newAddress.city) { this.$toast.error("Preencha a cidade do endereço"); return }
      if (!this.newAddress.state) { this.$toast.error("Preencha o estado do endereço"); return }
      
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      await usersColl.doc(auth.currentUser.uid).collection('Addresses').add({
        Name: this.newAddress.name,
        Cep: this.newAddress.cep,
        Street: this.newAddress.street,
        Number: this.newAddress.number,
        Neighborhood: this.newAddress.neighborhood,
        Complement: this.newAddress.complement,
        City: this.newAddress.city,
        State: this.newAddress.state,
      })
      .then(() => { this.getAddresses(), this.$toast.success('Endereço adicionado com sucesso!'), this.$modal.hide('newAddress'), this.newAddress = { cep: '', name: '', street: '', number: '', city: '', state: '', complement: '', neighborhood: '' } })
      .catch(() => { this.$toast.error('Erro ao salvar endereço, tente novamente') })
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    },

    async deleteAddress() {
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      await usersColl.doc(auth.currentUser.uid).collection('Addresses').doc(this.addressFocus.id).delete()
      .then(() => { this.getAddresses(); this.$toast.success('Endereço excluído com sucesso!'); this.$modal.hide('deleteAddress'); this.addressFocus = '' })
      .catch(() => { this.$toast.error('Erro ao excluir endereço, tente novamente') })
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    },

    async updateData() {
      if (!this.myData.name || !this.myData.cell) { this.$toast.error('Preencha todos os campos'); return }
      if (this.myData.docm.length < 18 && this.myData.nasc.length !== 10) { this.$toast.error('Data de Nascimento inválida.'); return }
      if (this.myData.docm.length > 14 && (!this.myData.juri.nfan || !this.myData.juri.insc || !this.myData.juri.end2.city || !this.myData.juri.end2.street || !this.myData.juri.end2.state || !this.myData.juri.end2.number || !this.myData.juri.end2.cep || !this.myData.juri.end2.neighborhood)) { this.$toast.error('Preencha todos os campos'); return }
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      await usersColl.doc(auth.currentUser.uid).update({
        Name: this.myData.name,
        Phone: this.myData.cell,
        ...(this.myData.docm.length <= 14 && { Nascimento: this.myData.nasc }),
        ...(this.myData.docm.length > 14 && { Juridico: {
          Ie: this.myData.juri.insc,
          NameFantasia: this.myData.juri.nfan,
          Address: {
            City: this.myData.juri.end2.city,
            State: this.myData.juri.end2.state,
            Street: this.myData.juri.end2.street,
            Number: this.myData.juri.end2.number,
            Postcode: this.myData.juri.end2.cep,
            Complement: this.myData.juri.end2.complement,
            Neighborhood: this.myData.juri.end2.neighborhood
          }
        } })
      }).then(() => { this.$toast.success('Dados atualizados!') }).catch(() => { this.$toast.error('Erro ao atualizar, tente novamente') })
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
      this.$store.dispatch('fetchUserProfile', auth.currentUser)
    },

    async updatePassword() {
      if (!this.newPassword.old || !this.newPassword.new) { this.$toast.error('Preencha todos os campos.'); return }
      if (!this.$store.state.loading) this.$store.commit('setLoading', true)
      await auth.currentUser.reauthenticateWithCredential(firebase.auth.EmailAuthProvider.credential(this.$store.state.user?.Email, this.newPassword.old))
      .then(async () => { await auth.currentUser.updatePassword(this.newPassword.new).then(() => { this.$toast.success('Senha atualizada com sucesso!'), this.$modal.hide('newPassword') }).catch(() => this.$toast.error('Erro ao atualizar, tente novamente e verifique a senha nova')) })
      .catch(() => this.$toast.error('Erro ao reautenticar, tente novamente e verifique a senha antiga.'))
      if (this.$store.state.loading) this.$store.commit('setLoading', false)
    }
  },

  watch: { '$store.state.user': function() { this.getInfo() } },

  filters: {
    elapsedTime(value) {

      const units = {
        year  : 24 * 60 * 60 * 1000 * 365,
        month : 24 * 60 * 60 * 1000 * 365/12,
        day   : 24 * 60 * 60 * 1000,
        hour  : 60 * 60 * 1000,
        minute: 60 * 1000,
        second: 1000
      }
  
      const rtf = new Intl.RelativeTimeFormat('pt-BR', { numeric: 'auto' })
      const elapsed = new Date(value) - new Date()

      for (var u in units) 
        if (Math.abs(elapsed) > units[u] || u == 'second') 
          return rtf.format(Math.round(elapsed/units[u]), u)
    },

    currency(value) {
      if (isNaN(value)) { return value }
      var formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2
      })
      return formatter.format(value)
    }
  },

  directives: { mask }
}
</script>